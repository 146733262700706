import { useState } from 'react'
import Header from '../../containers/header'
import Menu from '../../containers/menu'
import Lessons from '../../containers/lessons'
import Topics from '../../containers/topics'
import Learn from '../../containers/learn'

const Main = () => {
  const [option, setOption] = useState({
    tab: 1,
    lesson: '',
    subject: '',
    topic: '',
  })

  return (
    <div className='container-fluid'>
      <Header />
      {option.tab === 1 && <Menu option={option} setOption={setOption} />}
      {option.tab === 2 && <Lessons option={option} setOption={setOption} />}
      {option.tab === 3 && <Topics option={option} setOption={setOption} />}
      {option.tab === 4 && <Learn option={option} setOption={setOption} />}
    </div>
  )
}
export default Main
