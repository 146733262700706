import Lottie from 'react-lottie'

const LottieFiles = ({ json, style }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: json,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div style={style}>
      <Lottie options={defaultOptions} />
    </div>
  )
}
export default LottieFiles
