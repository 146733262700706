import { useState, useEffect } from 'react'
import Box from '../../components/box'
import leftIcon from '../../images/left.png'
import rightIcon from '../../images/right.png'
import { data } from '../../data'

const Learn = ({ option, setOption }) => {
  const topicData = [
    'TANZİMAT ŞİİRİ',
    'SERVETİFÜNUN ŞİİRİ',
    'FECRİATİ ŞİİRİ',
    'MİLLİ EDEBİYAT ŞİİRİ',
    'CUMHURİYET ŞİİRİ',
    'HALK ŞİİRİ',
    'DİVAN ŞİİRİ',
  ]

  const [poets, setPoets] = useState(0)

  const [filteredData, setFilteredData] = useState([])

  const [currentClass, setCurrentClass] = useState('show-front')

  const dataFilter = (select) => {
    if (select !== option.topic) {
      let filtered

      if (select) {
        setCurrentClass('show-front')
        setPoets(0)
        setOption({ ...option, topic: select })
        filtered = data?.filter((item) => item?.period === select)
      } else {
        filtered = data?.filter((item) => item?.period === option.topic)
      }

      setFilteredData(filtered)
    }
  }

  useEffect(() => {
    dataFilter()
  }, [option])

  const [opacity, setOpacity] = useState(false)

  const opacityChange = () => {
    setOpacity(true)
    setTimeout(() => {
      setOpacity(false)
    }, 150)
  }

  const nextPrevious = (process) => {
    if (process === 'next') {
      if (poets !== filteredData?.length - 1) {
        setPoets(poets + 1)
        opacityChange()
        if (currentClass !== 'show-front') {
          setCurrentClass('show-front')
        }
      } else {
        setPoets(filteredData?.length - 1)
      }
    } else if (process === 'previous') {
      if (poets !== 0) {
        setPoets(poets - 1)
        opacityChange()
        if (currentClass !== 'show-front') {
          setCurrentClass('show-front')
        }
      } else {
        setPoets(0)
      }
    }
  }

  useEffect(() => {
    setOption({ ...option, topic: topicData[0] })
  }, [])

  return (
    <div className='row px-2 px-lg-0 mb-5'>
      <div
        className='col-12 col-lg-6 offset-lg-3 p-0 d-inline d-flex justify-content-between align-items-center'
        style={
          {
            // borderBottom: '1px solid black',
            // boxShadow: '0 0.25rem 1rem gray',
          }
        }
      >
        <div>
          <span style={{ fontWeight: 'bold', fontSize: '23px' }}>
            {option.subject}:
          </span>
          <span className='mx-2' style={{ fontWeight: 400, fontSize: '20px' }}>
            {option.topic}
          </span>
        </div>
        <button
          onClick={() => setOption({ ...option, tab: 3 })}
          className='mb-2 thirdButton'
          style={{
            width: '120px',
            height: '35px',
          }}
        >
          Geri dön
        </button>
      </div>

      <div
        className='col-12 col-xl-4 offset-xl-3'
        style={{
          backgroundColor: 'white',
          boxShadow: '0 0.25rem 1rem gray',
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
        }}
      >
        <div
          className='d-flex justify-content-center align-items-center flex-column mt-1'
          style={{ position: 'relative' }}
        >
          <div
            className='relativeBox'
            style={{
              zIndex: opacity ? 1 : 0,
              opacity: opacity ? 1 : 0,
              transitionDuration: !opacity && '500ms',
            }}
          ></div>
          {filteredData?.map(
            (item, i) =>
              i === poets && (
                <Box
                  item={item}
                  currentClass={currentClass}
                  setCurrentClass={setCurrentClass}
                />
              )
          )}
        </div>
        <div className='d-flex justify-content-center mb-3'>
          <button
            onClick={() => nextPrevious('previous')}
            className='mx-4'
            style={{ border: 'none', backgroundColor: 'transparent' }}
            disabled={opacity}
          >
            <img src={leftIcon} width='45px' alt='' />
          </button>
          <button
            onClick={() => nextPrevious('next')}
            className='mx-4'
            style={{ border: 'none', backgroundColor: 'transparent' }}
            disabled={opacity}
          >
            <img src={rightIcon} width='45px' alt='' />
          </button>
        </div>
      </div>
      <div
        className='col-12 col-lg-2 p-0 px-3'
        style={{
          boxShadow: '0 0.25rem 1rem gray',
          backgroundColor: ' white	',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
      >
        {topicData.map((item, i) => (
          <button
            onClick={() => {
              dataFilter(item)
            }}
            className={` d-flex justify-content-center align-items-center pt-2 w-100 py-1 my-4  ${
              option.topic === item ? 'topicButton' : 'defaultButton'
            }`}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  )
}
export default Learn
