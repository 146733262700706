import edebikodLogo from '../../images/edebikod.png'

const Header = () => {
  return (
    <div className='row'>
      <div className='col-12 text-center mt-4'>
        <img src={edebikodLogo} width='200px' alt='' />
      </div>
    </div>
  )
}
export default Header
