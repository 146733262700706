import biologyIcon from '../../images/biology.png'
import chemistryIcon from '../../images/chemistry.png'
import geographyIcon from '../../images/geography.png'
import geometryIcon from '../../images/geometry.png'
import historyIcon from '../../images/history.png'
import languageIcon from '../../images/language.png'
import literatureIcon from '../../images/literature.png'
import mathIcon from '../../images/math.png'
import philosophyIcon from '../../images/philosophy.png'
import physicalIcon from '../../images/physical.png'

const Lessons = ({ option, setOption }) => {
  const lessons1 = [
    {
      id: 1,
      name: 'Türk Dili ve Edebiyatı',
      icon: literatureIcon,
    },
    {
      id: 2,
      name: 'Dil ve Anlatım',
      icon: languageIcon,
    },
    {
      id: 3,
      name: 'Tarih',
      icon: historyIcon,
    },
    {
      id: 4,
      name: 'Coğrafya',
      icon: geographyIcon,
    },
    {
      id: 5,
      name: 'Felsefe',
      icon: philosophyIcon,
    },
  ]

  const lessons2 = [
    {
      id: 6,
      name: 'Matematik',
      icon: mathIcon,
    },
    {
      id: 7,
      name: 'Geometri',
      icon: geometryIcon,
    },
    {
      id: 8,
      name: 'Fizik',
      icon: physicalIcon,
    },
    {
      id: 9,
      name: 'Kimya',
      icon: chemistryIcon,
    },
    {
      id: 10,
      name: 'Biyoloji',
      icon: biologyIcon,
    },
  ]

  return (
    <div className='row'>
      <div className='mb-2 text-center'>
        <h2 style={{ fontWeight: 500 }}>Bilgiye ulaşmanın daha basit yolu!</h2>
      </div>
      <div
        className='col-10 offset-1 col-md-6 offset-md-3 col-xl-6 offset-xl-3 d-flex d-xl-block justify-content-center align-items-center mb-5 py-4 py-xl-0'
        style={{
          backgroundColor: 'white',
          borderRadius: '4px',
          boxShadow: '0 0.25rem 1rem gray',
        }}
      >
        <div className='d-xl-flex justify-content-around align-items-center p-xl-2 mt-xl-3'>
          {lessons1.map((item) => (
            <div
              onClick={() =>
                setOption({ ...option, tab: 3, lesson: item.name })
              }
              className='menuBox d-flex flex-column justify-content-center align-items-center text-center p-1 p-xl-4 m-1'
              style={{
                width: '150px',
                height: '150px',
              }}
            >
              <img
                src={item.icon}
                width='35px'
                height='35px'
                className='mb-2'
                alt=''
              />
              <span style={{ fontWeight: 600, fontSize: '20px' }}>
                {item.name}
              </span>
            </div>
          ))}
        </div>

        <div className='d-xl-flex justify-content-around align-items-center p-xl-2  mb-xl-3'>
          {lessons2.map((item) => (
            <div
              onClick={() =>
                setOption({ ...option, tab: 3, lesson: item.name })
              }
              className='menuBox d-flex flex-column justify-content-center align-items-center text-center p-1 p-xl-4 m-1'
              style={{
                width: '150px',
                height: '150px',
              }}
            >
              <img
                src={item.icon}
                width='35px'
                height='35px'
                className='mb-2'
                alt=''
              />
              <span style={{ fontWeight: 600, fontSize: '20px' }}>
                {item.name}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center py-3 mb-5'>
        <button
          onClick={() => setOption({ ...option, tab: 1 })}
          style={{ width: '100px', height: '100px' }}
          className='secondButton'
        >
          Geri dön
        </button>
      </div>
    </div>
  )
}
export default Lessons
