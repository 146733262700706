import pinIcon from '../../images/pin.png'

const Topics = ({ option, setOption }) => {
  const subjectData = [
    {
      id: 1,
      lesson: 'Türk Dili ve Edebiyatı',
      subject: 'Edebiyat Şairleri',
    },
  ]

  return (
    <div className='row'>
      <div className='mb-2 text-center'>
        <h2 style={{ fontWeight: 500 }}>Bilgiye ulaşmanın daha basit yolu!</h2>
      </div>
      <div
        className='col-10 offset-1 col-md-6 offset-md-3 col-xl-6 offset-xl-3 d-flex d-xl-block justify-content-center align-items-center mb-5 py-4 py-xl-0'
        style={{
          backgroundColor: 'white',
          borderRadius: '4px',
          boxShadow: '0 0.25rem 1rem gray',
        }}
      >
        <div className='d-xl-flex justify-content-around align-items-center p-xl-2 mt-xl-3 text-center'>
          {subjectData.map(
            (item) =>
              option.lesson === item.lesson && (
                <div
                  onClick={() =>
                    setOption({ ...option, tab: 4, subject: item.subject })
                  }
                  className='menuBox d-flex flex-column justify-content-center align-items-center text-center p-1 p-xl-4 m-1'
                  style={{
                    width: '150px',
                    height: '150px',
                  }}
                >
                  <img src={pinIcon} className='mb-2' width='25px' alt='' />
                  <span style={{ fontWeight: 600, fontSize: '20px' }}>
                    {item.subject}
                  </span>
                </div>
              )
          )}
          <button
            className='thirdButton text-center p-1 p-xl-4 m-1'
            style={{
              width: '100px',
              height: '100px',
            }}
          >
            <span style={{ fontWeight: 600, fontSize: '20px' }}>+Ekle</span>
          </button>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center py-3 mb-5'>
        <button
          onClick={() => setOption({ ...option, tab: 2, subject: '' })}
          style={{ width: '100px', height: '100px' }}
          className='secondButton'
        >
          Geri dön
        </button>
      </div>
    </div>
  )
}
export default Topics
