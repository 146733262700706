export const data = [
  {
    name: 'ŞİNASİ',
    period: 'TANZİMAT ŞİİRİ',
    features: [
      'Noktalama işaretlerini ilk defa kullanan şairdir',
      'Türk edebiyatında yayımlanan ilk tiyatronun sahibidir - Şair Evlenmesi',
      'İlk makale örneğini yazmıştır - Tercüman-ı Ahval Mukaddimesi',
      'İlk özel gazetenin sahibidir - Tercüman-ı Ahval',
      'Başlattığı yeniliklerle Tanzimat Dönemi edebiyatında öncüdür',
    ],
  },
  {
    name: 'NAMIK KEMAL',
    period: 'TANZİMAT ŞİİRİ',
    features: [
      'Vatan Şairi olarak ün kazanmıştır',
      'Tiyatroyu faydalı bir eğlence olarak değerlendirir',
      'Şiirlerinde romantizm akımının özellikleri vardır',
      'İlk edebi romanın sahibidir - İntibah',
      'İlk tarihi romanın sahibidir - Cezmi',
      'Tanzimat Döneminde eleştiri türünün en önemli ismidir - Tahrib-i Harabat',
    ],
  },
  {
    name: 'ZİYA PAŞA',
    period: 'TANZİMAT ŞİİRİ',
    features: [
      'Eski ile yeni arasında bocalamış bir şairdir',
      'Özdeyiş haline gelmiş beyitleriyle meşhurdur',
      'Terkib-i Bent’i meşhurdur',
      'Toplum yapısındaki çarpıklıkları eleştirmiştir',
      'Şiirlerini didaktik tarzda yazmıştır',
    ],
  },
  {
    name: 'RECAİZADE MAHMUT EKREM',
    period: 'TANZİMAT ŞİİRİ',
    features: [
      'II. Dönem Tanzimat edebiyatında üstat olarak kabul edilmiştir',
      'Namık Kemal ile birlikte Encümen-i Şuara topluluğuna katılmıştır',
      'Servetifünun edebiyatına zemin hazırlamıştır',
      'Eski karşısında her zaman yeni edebiyatı savunmuştur',
      'İlk realist romanın sahibidir - Araba Sevdası',
    ],
  },
  {
    name: 'ABDÜLHAK HAMİT TARHAN',
    period: 'TANZİMAT ŞİİRİ',
    features: [
      'Şair-i azam olarak kabul edilir',
      'Tezatlar Şairi',
      'Sadece şiir ve tiyatro türünde eser vermiştir',
      'Divan edebiyatının bütün kurallarını yıkmıştır',
      'Tezatlık ve düzensizlik şiirlerinin en belirgin özelliğidir',
      'Tiyatroları sahnelenmeye uygun değildir',
    ],
  },
  {
    name: 'MUALLİM NACİ',
    period: 'TANZİMAT ŞİİRİ',
    features: [
      'Divan edebiyatına yakındır',
      'Ilımlı, orta yol tutturan bir sanatçı olarak bilinir',
      'Eski-yeni tartışmasını ortaya çıkarmıştır',
      'Eskinin savunucusu ve temsilcisidir',
    ],
  },
  {
    name: 'CENAP ŞAHABETTİN',
    period: 'SERVETİFÜNUN ŞİİRİ',
    features: [
      'Alışılmamış bağdaştırma ve imgelerle tartışmalara sebep olmuştur',
      'Sözcüklerle tablo yapma şiirlerinde hâkimdir',
      'Sembolizm ve parnasizm akımlarından etkilenmiştir',
      'Bütün şiirlerini aruz ölçüsü ile yazmıştır',
      'Hiçbir eserinde toplumsal sorunlara değinmemiştir',
    ],
  },
  {
    name: 'TEVFİK FİKRET',
    period: 'SERVETİFÜNUN ŞİİRİ',
    features: [
      'Aruzun kalıplarını müzikalite bakımından değerlendirmiştir',
      'Şermin adlı kitabı dışında bütün şiirlerini aruzla yazmıştır',
      'Parnasizm akımından etkilenmiştir',
      'Bireysel temaların yanında toplumsal konulara değinmiştir',
      'Manzum hikâyeleri önemlidir',
      'Müstezatı genişletmiştir ( Serbest müstezat)',
    ],
  },
  {
    name: 'AHMET HAŞİM',
    period: 'FECRİATİ ŞİİRİ',
    features: [
      'Sembolizm akımından etkilenmiştir',
      'Tüm şiirlerinde aruz ölçüsünü kullanmıştır',
      'Hece ölçüsüne köylü vezni demiştir',
      'Aşk, tabiat, Bağdat’ta geçen çocukluğu önemli temalarıdır',
      'Akşam Şairi olarak bilinir',
      'Akşam, gece, gün sonu, akşam kızıllığı, sararmış yapraklar',
    ],
  },
  {
    name: 'MEHMET EMİN YURDAKUL',
    period: 'MİLLİ EDEBİYAT ŞİİRİ',
    features: [
      'İlk şiiri Cenge Giderken ile adını duyurmuştur',
      '‘’Ben bir Türk’üm; dinim, cinsim uludur.’’ dizeleriyle tanınmıştır',
      'Milliyetçilik ve Türkçülük akımı hâkimdir',
      'Bütün şiirlerinde hece ölçüsünü kullanmıştır',
      'Şiiri öğretici (didaktik) şiirlerdir',
    ],
  },
  {
    name: 'ZİYA GÖKALP',
    period: 'MİLLİ EDEBİYAT ŞİİRİ',
    features: [
      'Turancılık düşüncesi hâkimdir',
      'Türkleşmek, İslamlaşmak, Muasırlaşmak',
      'Genç Kalemler ’de Yeni Lisan tezinin fikir babasıdır',
      'Destan ve masal türlerinde de çalışmaları bulunur',
      'Hece ölçüsü ile didaktik şiirleri vardır',
    ],
  },
  {
    name: 'FARUK NAFİZ ÇAMLIBEL',
    period: 'MİLLİ EDEBİYAT ŞİİRİ',
    features: [
      'Beş Hececiler topluluğu içerisindedir',
      'İlk şiirlerini aruzla yazmıştır',
      'Milli edebiyat akımı ile aruzu bırakarak heceyi kullanmıştır',
      'Halk şiirini örnek almıştır',
      'Beş Hececiler’in en başarılı ve lirik şiirler yazan şairidir',
      'Han Duvarları Şairi',
      '‘’Sanat’’ adlı şiiri memleketçi şiirin bildirisi niteliğindedir',
    ],
  },
  {
    name: 'HALİT FAHRİ OZANSOY',
    period: 'MİLLİ EDEBİYAT ŞİİRİ',
    features: [
      'Beş Hececiler topluluğu içerisindedir',
      'İlk şiirlerini aruzla yazmıştır',
      'Milli edebiyat akımı ile aruzu bırakarak heceyi kullanmıştır',
      'Halk şiirini örnek almıştır',
      '‘’Aruza Veda’’ şiiriyle aruz ölçüsünü bırakıp heceye geçmiştir',
      'Genellikle karamsar duyguları işlemiştir',
    ],
  },
  {
    name: 'ENİS BEHİÇ KORYÜREK',
    period: 'MİLLİ EDEBİYAT ŞİİRİ',
    features: [
      'Beş Hececiler topluluğu içerisindedir',
      'İlk şiirlerini aruzla yazmıştır',
      'Milli edebiyat akımı ile aruzu bırakarak heceyi kullanmıştır',
      'Halk şiirini örnek almıştır',
      'Epik şiirler yazmıştır',
      'Sonraki yıllarda mistik şiire yönelmiş ve tasavvufi şiirler işlemiştir',
    ],
  },
  {
    name: 'YUSUF ZİYA ORTAÇ',
    period: 'MİLLİ EDEBİYAT ŞİİRİ',
    features: [
      'Beş Hececiler topluluğu içerisindedir',
      'İlk şiirlerini aruzla yazmıştır',
      'Milli edebiyat akımı ile aruzu bırakarak heceyi kullanmıştır',
      'Halk şiirini örnek almıştır',
      'Akbaba adlı mizah dergisini çıkarmıştır',
      'Çimdik mahlasını kullanmıştır',
    ],
  },
  {
    name: 'ORHAN SEYFİ ORHON',
    period: 'MİLLİ EDEBİYAT ŞİİRİ',
    features: [
      'Beş Hececiler topluluğu içerisindedir',
      'İlk şiirlerini aruzla yazmıştır',
      'Milli edebiyat akımı ile aruzu bırakarak heceyi kullanmıştır',
      'Halk şiirini örnek almıştır',
      'İlk şiirlerini aruzla yazmıştır ( Fırtına ve Kar aruzla yazılmış)',
      'Milli duyguları işlemiştir',
    ],
  },
  {
    name: 'YAHYA KEMAL BEYATLI',
    period: 'MİLLİ EDEBİYAT ŞİİRİ',
    features: [
      'Saf şiir anlayışını savunmuştur',
      'İstanbul Şairi',
      'Parnasizm ve sembolizm akımını benimsemiştir',
      'Ok şiiri dışında tüm şiirlerinde aruz ölçüsünü kullanmıştır',
      'Nazmı nesirden uzaklaştırmıştır',
      'İstanbul’a duyduğu sevgiyi şiirlerinde işlemiştir',
      'Şiir ve düzyazılarını sağlığında kitap haline getirmemiştir',
    ],
  },
  {
    name: 'AHMET HAMDİ TANPINAR',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Edebiyatın hemen hemen her türünde eser vermiştir',
      'Saf şiir anlayışını savunmuştur',
      'Rüya, ruh durumları, bilinçaltı, geçmişe özlem temalarını işler',
      'İlk şiirleri hece ölçüsü sonra serbest şiire geçmiştir',
      'Zaman kavramı onun için önemlidir',
    ],
  },
  {
    name: 'NECİP FAZIL KISAKÜREK',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Modern mistisizmin kurucusu',
      'Çile şairi / Kaldırımlar şairi',
      'Saf şiir anlayışına bağlıdır',
      'İnsan-evren ilişkisi, madde-ruh çatışmasını işler',
      'Asıl ününü tiyatro ve şiirleriyle yakalamıştır',
    ],
  },
  {
    name: 'AHMET MUHİP DIRANAS',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Şiir ve tiyatro türünde eser vermiştir (Asıl ünü şiirleri ile)',
      'Şiirlerinin tümünü hece ölçüsü ile yazmıştır',
      'Sembolizm',
      'Saf şiir anlayışı',
      'Ölçü ve uyağı şiirin önemli unsurlarından saymıştır',
    ],
  },
  {
    name: 'ASAF HALET ÇELEBİ',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Modern gelenekçi',
      'Soyut şiirin tanımını ilk defa yapmıştır',
      'Sezgi şairi',
      'Önceleri divan tarzında gazel ve rubailer yazmıştır',
      'Tasavvuftan etkilenmiştir ( Doğu mistisizmi, mistik, soyut bir şiir dünyası)',
    ],
  },
  {
    name: 'CAHİT SITKI TARANCI',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Otuz Beş Yaş şairi',
      'Ölüm şairi',
      'Sembolizm',
      'Saf şiir temsilcisi',
      'Ölüm korkusu, yaşama sevinci, çocukluk özlemi başlıca temalarıdır',
    ],
  },
  {
    name: 'ZİYA OSMAN SABA',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Yedi Meşaleciler topluluğundadır - Cumhuriyet Dönemi’nin ilk şairler topluluğudur',
      'Saf şiir anlayışı / Sembolizm',
      'Beş Hececiler ’in memleketçi şiirlerini eleştirir',
      'Çocukluk özlemi, anılar, ev ve aile sevgisi başlıca temalarıdır',
      'Yedi Meşaleciler topluluğunun en başarılı ismidir',
      'Şiir ve öykü türünde eser vermiştir',
    ],
  },
  {
    name: 'YAŞAR NABİ NAYIR',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Yedi Meşaleciler topluluğundadır - Cumhuriyet Dönemi’nin ilk şairler topluluğudur',
      'Saf şiir anlayışı / Sembolizm',
      'Beş Hececiler ’in memleketçi şiirlerini eleştirir',
      'Varlık dergisini çıkarmıştır',
    ],
  },
  {
    name: 'KENAN HULUSİ KORAY',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Yedi Meşaleciler topluluğundadır - Cumhuriyet Dönemi’nin ilk şairler topluluğudur',
      'Saf şiir anlayışı / Sembolizm',
      'Beş Hececiler ’in memleketçi şiirlerini eleştirir',
      'Yedi Meşaleciler ‘in öykücüsüdür',
      'Düzyazı türünde eser vermiştir',
    ],
  },
  {
    name: 'VASFİ MAHİR KOCATÜRK',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Yedi Meşaleciler topluluğundadır - Cumhuriyet Dönemi’nin ilk şairler topluluğudur',
      'Saf şiir anlayışı / Sembolizm',
      'Beş Hececiler ’in memleketçi şiirlerini eleştirir',
      'Şiirlerinin temaları; kahramanlık, milli duygulardır',
      'Edebiyat tarihi çalışmaları yapmıştır',
    ],
  },
  {
    name: 'SABRİ ESAT SİYAVUŞGİL',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Yedi Meşaleciler topluluğundadır - Cumhuriyet Dönemi’nin ilk şairler topluluğudur',
      'Saf şiir anlayışı / Sembolizm',
      'Beş Hececiler ’in memleketçi şiirlerini eleştirir',
      'Eşya ve görünüm tasvirlerinde canlı şiirleri vardır',
    ],
  },
  {
    name: 'CEVDET KUDRET SOLOK',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Yedi Meşaleciler topluluğundadır - Cumhuriyet Dönemi’nin ilk şairler topluluğudur',
      'Saf şiir anlayışı / Sembolizm',
      'Beş Hececiler ’in memleketçi şiirlerini eleştirir',
      'Edebiyat araştırmalarıyla bilinir',
    ],
  },
  {
    name: 'MUAMMER LÜTFİ BAHŞİ',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Topluluğun dağılmasından sonra tamamıyla edebiyattan kopmuştur',
      'Yedi Meşaleciler topluluğundadır - Cumhuriyet Dönemi’nin ilk şairler topluluğudur',
      'Saf şiir anlayışı / Sembolizm',
      'Beş Hececiler ’in memleketçi şiirlerini eleştirir',
    ],
  },
  {
    name: 'NAZIM HİKMET',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Toplumcu gerçekçi şiirin öncüsü',
      'Fütürizm akımı',
      'Ona göre önemli olan özdür ve bu öz politik bir nitelik taşır',
      'İdeolojik tutumu sanatın temeline yerleştirir',
      'Basamaklı dizeler, büyük-küçük harf kullanımını farklı kullanma görülür',
    ],
  },
  {
    name: 'RIFAT ILGAZ',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Toplumcu gerçekçi şiir',
      'Şairanelikten uzak bir sanat anlayışı',
      'Hababam Sınıfı',
      'Şiirlerinde bulunan öyküleme ve ironi dikkat çeker',
      'Devrin sosyal yapısı ve geçim sıkıntısı eserlerinde işlenir',
    ],
  },
  {
    name: 'AHMET ARİF',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Toplumcu gerçekçi şiir',
      'Öfke, isyan, kavga gibi sert bir üslup',
      'Hasretinden Prangalar Eskittim',
    ],
  },
  {
    name: 'ATTİLA İLHAN',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Mavi Hareketi içerisinde yer alır',
      '1940 kuşağı toplumcu gerçekçi şiirin son temsilcilerinden biridir',
      'Onun şiirinin ilk kaynaklarından birisi Nazım Hikmet’tir',
      'Geleneğe önem vererek divan ve halk edebiyatını sentezlemiştir',
      'Ben Sana Mecburum, Sisler Bulvarı, Ayrılık Sevdaya Dâhil',
    ],
  },
  {
    name: 'AHMET KUTSİ TECER',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Âşık Veysel’i Türk edebiyatına tanıtan kişidir',
      'Karacaoğlan ve Yunus Emre’nin hayatına ışık tutmuştur',
      'Anadolu folklorunu yansıtır',
      'Araştırma, inceleme ve tiyatro dallarında yapıt vermiştir',
      'Hece ölçüsü ve duygusal şiirler',
    ],
  },
  {
    name: 'KEMALETTİN KAMU',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Memleket edebiyatı içerisinde yer alır',
      'Ben gurbette değilim / Gurbet benim içimde dizeleriyle tanınır',
      'Gurbet Şairi',
      'Yalın bir dili vardır',
      'Bingöl Çobanları',
    ],
  },
  {
    name: 'ZEKİ ÖMER DEFNE',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Memleket edebiyatı içerisinde yer alır',
      'Yurt güzellemeleri ile tanınır',
      'Ana teması Anadolu’dur',
    ],
  },
  {
    name: 'ORHAN ŞAİK GÖKYAY',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Şiirlerinin yanı sıra eleştiri ve edebiyat araştırmaları ile tanınır',
      'Lirik-epik şiirler yazmıştır',
      'Bu Vatan Kimin şiiri ünlüdür',
      'Destursuz Bağa Girenler',
    ],
  },
  {
    name: 'NECMETTİN HALİL ONAN',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Anadolu gerçeklerini işlemiştir',
      'Bir Yolcuya şiiri ünlüdür',
      'Memleketçi edebiyat çerçevesinde eser yazmıştır',
    ],
  },
  {
    name: 'BEHÇET KEMAL ÇAĞLAR',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Atatürk sevgisi ve ulusal konuları şiirlerinde işlemiştir',
      'Faruk Nafiz Çamlıbel’le birlikte Onuncu Yıl Marşı’nı yazmıştır',
      'Ankaralı Âşık Ömer takma adını kullanır',
    ],
  },
  {
    name: 'ARİF NİHAT ASYA',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Lirik, epik ve didaktik söylemi birleştirir',
      'Vatan, bayrak, millet temalarını işlemiştir',
      'Bayrak Şairi olarak tanınır',
      'Rubai türünde başarılı örnekleri vardır',
      'Bir Bayrak Rüzgâr Bekliyor',
    ],
  },
  {
    name: 'ÖMER BEDRETTİN UŞAKLI',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Memleketçi edebiyat içerisindedir',
      'Hece ölçüsünü kullanır',
      'Anadolu’nun pastoral güzelliklerini anlatır',
      'Deniz, gurbet, hasret temalarını işlemiştir',
    ],
  },
  {
    name: 'BEDRİ RAHMİ EYÜBOĞLU',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Renk ögesi ön plandadır',
      'Şiirlerinin çoğu sosyal içeriklidir',
      'Anadolu insanının yoksul hayatını anlatır',
      'Ressam Şair',
      'Karadut şiiri ünlüdür',
    ],
  },
  {
    name: 'ORHAN VELİ KANIK',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Garip (I. Yeni) hareketinin en önemli ismidir',
      'Geleneksel şiire karşı çıkmıştır',
      'Şiirlerinde günlük hayatın insanını anlatır',
      'Nasrettin Hoca ve La Fontaine ile ilgili çalışmaları vardır',
      'Sürrealizm',
    ],
  },
  {
    name: 'MELİH CEVDET ANDAY',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Garip ( I. Yeni) hareketinin öncülerindendir',
      'Ölçü, uyak gibi geleneksel unsurları reddetmiştir',
      'Mitolojik unsurları şiirine dâhil etmiştir',
      'Mikado’nun Çöpleri',
      'Sürrealizm',
    ],
  },
  {
    name: 'OKTAY RİFAT HOROZCU',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Garip hareketinin öncülerindendir',
      'Şiirlerinde sürekli bir arayış içerisinde olmuştur',
      'Garip’ten sonra Perçemli Sokak eseriyle II. Yeni tarzında şiire yönelmiştir',
      'Sürrealizm',
    ],
  },
  {
    name: 'FAZIL HÜSNÜ DAĞLARCA',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Şiirleri genellikle epik-dramatik, lirik-didaktiktir',
      'En duyarlı olduğu konu Türkçeyle evrenselleşmektir',
      '‘’Türkçem benim ses bayrağım.’’ dizeleriyle tanınır',
      'Destan şairi',
    ],
  },
  {
    name: 'BEHÇET NECATİGİL',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Evlerin öyküsünü yazan şair',
      '‘’Evler şairi’’ olarak tanınmıştır',
      'Ev-aile-yakın çevre üçgeninde olayları ele alır',
      'Tevriye sanatı onun şiirlerinde ayırıcı bir özelliktir',
    ],
  },
  {
    name: 'CAHİT KÜLEBİ',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Halk şiiri geleneğinden beslenir',
      'Karacaoğlan’ın Bacanağı olarak tanınır',
      'Şiirlerinde lirizm ve içtenlik hâkimdir',
    ],
  },
  {
    name: 'EDİP CANSEVER',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'İkinci Yeni şairidir',
      'Kimi şiirlerinde tragedyadan esinlenen diyaloglar kurmuştur',
      'Masa da Masaymış ha, Yerçekimli Karanfil en tanınmış şiirleridir',
      'Sürrealizm',
    ],
  },
  {
    name: 'ECE AYHAN',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'İkinci Yeni’nin en özgün şairidir',
      'İkinci Yeni’nin en kapalı şiirlerini yazmıştır',
      'Şiirde sürrealist teknikleri başarıyla uygulamıştır',
      'Kınar Hanım’ın Denizleri, Bakışsız Bir Kedi Kara en tanınmış şiirleridir',
    ],
  },
  {
    name: 'İLHAN BERK',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'İkinci Yeni’nin en güçlü savunucusu',
      'Bu akımın ilkelerine en sadık şairidir',
      'Zengin çağrışımlı, soyut anlatımlara dayanan şiirleri vardır',
      'Güneşi Yakanların Selamı, Galile Denizi en tanınmış şiirleridir',
      'Sürrealizm',
    ],
  },
  {
    name: 'CEMAL SÜREYA SEBER',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      '‘’İkinci Yeni benim.’’ diyen şairdir',
      'İlk şiir kitabı Üvercinka’dır',
      'Şiirin yanı sıra deneme ve eleştirileriyle de tanınmıştır',
      'Papirüs dergisini çıkarmıştır',
      'Sürrealizm',
    ],
  },
  {
    name: 'TURGAY UYAR',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'İkinci Yeni hareketinin en önemli şairlerindendir',
      'Göğe Bakma Durağı şiiri ünlüdür',
      'Dili imgelere, çağrışımlara dayalı ve kapalıdır',
      'Türk şiiri üzerine yazılarıyla da ilgi görmüştür',
      'Sürrealizm',
    ],
  },
  {
    name: 'ÜLKÜ TAMER',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'İkinci Yeni tarzı şiirler',
      'Şiirleri soyutlamalarla dolu, imgelidir',
      'Şiirlerinde İngiliz ve Amerikan şairlerinin etkisi görülür',
      'Çocuk duyarlılığını anlatması şiirlerinin karakteristik bir yanıdır',
    ],
  },
  {
    name: 'SEZAİ KARAKOÇ',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'İkinci Yeni temsilcilerindendir',
      'Diriliş Şairi olarak tanınır',
      'Metafizik algının dini bir duyarlılığa dayanması gerektiğini savunur',
      'Modern ve geleneksel olanı sentezler',
      'Tasavvufla olan bağını şiirlerine yansıtır',
    ],
  },
  {
    name: 'CAHİT ZARİFOĞLU',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Sezai Karakoç ile Necip Fazıl çizgisinde yer alır',
      'Mistik bir şairdir',
      'Tasavvufa ve toplumsal olana eserlerinde yer verir',
      'Yedi Güzel Adam',
    ],
  },
  {
    name: 'HİLMİ YAVUZ',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'İlk şiir kitabı Bakış Kuşu’dur',
      'İkinci Yeni’ye yaklaşmakla birlikte İkinci Yeni’nin şiir anlayışını benimsemedi',
      'İslam mistisizminden, tasavvuftan yararlandı',
    ],
  },
  {
    name: 'ERDEM BEYAZIT',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'İslam’a yönelen şiir hareketinin öncülerindendir',
      'Sebep Ey, Risaleler eserleri önemlidir',
    ],
  },
  {
    name: 'NURİ PAKDİL',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'İslam’a yönelen şiir hareketinin öncülerindendir',
      'Kudüs Şairi',
      'Anneler ve Kudüsler, Biat önemli eserleridir',
    ],
  },
  {
    name: 'MEHMET ÇINARLI',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Geleneğe bağlıdır',
      'Hisar akımının ve Hisar dergisinin kurucularındandır',
      'Aruzla da heceyle de şiirler yazmıştır',
    ],
  },
  {
    name: 'YAVUZ BÜLENT BAKİLER',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Geleneğe bağlı bir şairdir',
      'Hisar akımının ve Hisar dergisinin kurucularındandır',
      'Eski ile yeni arasında bir köprü olmuştur',
      'İslam ruhu, milli ögeler onun şiirinin temelini oluşturur',
      'Eserlerinde herhangi bir ideolojiye hizmet etmemiştir',
      'Yalnızlık, Duvak, Seninle, Harman önemli eserleridir',
    ],
  },
  {
    name: 'NİYAZİ YILDIRIM GENÇOSMANOĞLU',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'Destan Şairi olarak tanınır',
      'Türk tarihini, kahramanlarını şiirleriyle destanlaştırmıştır',
      'Türk Tarihini bir bütünlük içinde ele almıştır',
    ],
  },
  {
    name: 'İSMET ÖZEL',
    period: 'CUMHURİYET ŞİİRİ',
    features: [
      'İlk şiirlerinde İkinci Yeni izlerini taşır',
      'Toplumcu şiirin başarılı örneklerini vermiştir',
      'Ataol Behramoğlu’yla Halkın Dostları dergisini çıkarmıştır',
      '‘Evet İsyan’ şiiriyle ideolojik şiirin içerisinde yer almıştır',
      '1970’lerde İslami, mistik bir dünya görüşüne yönelmiştir',
    ],
  },
  {
    name: 'KAYIKÇI KUL MUSTAFA',
    period: 'HALK ŞİİRİ',
    features: [
      '17. yy',
      'Yeniçeri şairlerindendir',
      'Devrin tarihi olaylarıyla ilgilenmiştir',
      'Genç Osman Destanı',
    ],
  },
  {
    name: 'KARACAOĞLAN',
    period: 'HALK ŞİİRİ',
    features: [
      '17. yy',
      'Güzelleme türünde lirik şiirler yazmıştır',
      'Divan ve tasavvuftan etkilenmemiştir',
    ],
  },
  {
    name: 'ÂŞIK ÖMER',
    period: 'HALK ŞİİRİ',
    features: [
      '17. yy',
      'Divan edebiyatı etkisinde kalmıştır',
      'Aruz ve hece ölçüsünü kullanmıştır',
      'Şairname adlı eseri önemlidir',
    ],
  },
  {
    name: 'DADALOĞLU',
    period: 'HALK ŞİİRİ',
    features: [
      '19. yy',
      'Toroslardaki Avşar Türklerindendir',
      'Koçaklamalarıyla bilinir',
      '‘’Ferman padişahın dağlar bizimdir’’ sözü ünlüdür',
    ],
  },
  {
    name: 'BAYBURTLU ZİHNİ',
    period: 'HALK ŞİİRİ',
    features: [
      '19. yy',
      'Divan ve Sergüzeştname adlı eseri önemlidir',
      'Divan şiirinden etkilenmiştir',
    ],
  },
  {
    name: 'ERZURUMLU EMRAH',
    period: 'HALK ŞİİRİ',
    features: [
      '19. yy',
      'Medrese eğitimi görmüştür',
      'Hem hece hem aruz ölçüsü kullanmıştır',
      'Koşma ve semaileriyle tanınır',
    ],
  },
  {
    name: 'ERZURUMLU İBRAHİM HAKKI',
    period: 'HALK ŞİİRİ',
    features: [
      '18. yy',
      'Dini-tasavvufi Türk şiiri temsilcisi',
      'Matematik, psikoloji, astronomi gibi alanlarda eser vermiştir',
      '‘’Mevla görelim n’eyler / N’eylerse güzel eyler’’ dizeleri ile tanınır',
      'İlahiname ve Marifetname eserleri önemlidir',
    ],
  },
  {
    name: 'SEYRANİ',
    period: 'HALK ŞİİRİ',
    features: [
      '19. yy',
      'Devrin büyüklerini hicvetmiştir',
      'Aruz ve hece ölçüsü kullanmıştır',
    ],
  },
  {
    name: 'ÂŞIK ŞEREF TAŞLIOVA',
    period: 'HALK ŞİİRİ',
    features: [
      '20. yy',
      'UNESCO’nun hazırladığı bir programda âşıklık geleneğini temsil etmiştir',
      'Gönül Bahçesi isimli şiir kitabı önemlidir',
    ],
  },
  {
    name: 'ÂŞIK VEYSEL',
    period: 'HALK ŞİİRİ',
    features: [
      '20. yy',
      'Cumhuriyet Dönemi şairlerindendir',
      'Çiçek hastalığı yüzünden gözlerini kaybetmiştir',
      'Ahmet Kutsi Tecer tarafından Türk edebiyatına tanıtılmıştır',
      'Âşık edebiyatının son temsilcisidir',
    ],
  },
  {
    name: 'YUNUS EMRE',
    period: 'HALK ŞİİRİ',
    features: [
      '13.yy',
      'Tasavvuf şiirinin en güçlü temsilcisidir',
      'İlahi türü denilince akla gelen ilk isimdir',
      'Hem hece hem aruz ölçüsü kullanmıştır',
      'Risaletü’n-Nushiyye adlı eseri önemlidir',
    ],
  },
  {
    name: 'HACI BEKTAŞ-I VELİ',
    period: 'HALK ŞİİRİ',
    features: [
      '13.yy',
      'Bektaşilik tarikatının kurucusudur',
      'Dini-tasavvufi Türk şiiri temsilcisidir',
      'Allah aşkı, nefsin terbiyesi gibi konuları işler',
      'Makalat önemli eseridir',
    ],
  },
  {
    name: 'KAYGUSUZ ABDAL',
    period: 'HALK ŞİİRİ',
    features: [
      '15.yy',
      'Bektaşi şairlerindendir',
      'Hece ve aruz ölçüsünü kullanmıştır',
      'Tasavvuf ilkelerini alay edercesine savunur',
      'Gevhername ve Budalaname önemli eserleridir',
    ],
  },
  {
    name: 'EŞREFOĞLU RUMİ',
    period: 'HALK ŞİİRİ',
    features: [
      '15.yy',
      'Tasavvuf şairlerindendir',
      'Yunus Emre’nin etkisinde kalmıştır',
      'Hem hece hem de aruzla şiirler yazmıştır',
      'Müzekki’n-Nüfus önemli eseridir',
    ],
  },
  {
    name: 'HOCA DEHHANİ',
    period: 'DİVAN ŞİİRİ',
    features: [
      '13.yy',
      'Din dışı konularda şiir yazmıştır',
      'Divan edebiyatının ilk temsilcisidir',
      'Selçuklu Şehnamesi önemli eseridir',
    ],
  },
  {
    name: 'MEVLANA',
    period: 'DİVAN ŞİİRİ',
    features: [
      '13.yy',
      'Tasavvuf konusu üzerinde durmuştur',
      'Hamdım, piştim, yandım',
      'Manzum eserleri: Mesnevi ve Divan-ı Kebir',
      'Mensur eserleri: Fihi Ma Fih ve Mecalis-i Seb’a',
    ],
  },
  {
    name: 'ÂŞIK PAŞA',
    period: 'DİVAN ŞİİRİ',
    features: [
      '13.yy',
      'Türk dilini savunmuştur',
      'Mutasavvıf bir şairdir',
      'Aruz ve hece ölçüsünü kullanmıştır',
      'Didaktik tarzda yapıtları vardır',
      'Garipname önemli eseridir',
    ],
  },
  {
    name: 'GÜLŞEHRİ',
    period: 'DİVAN ŞİİRİ',
    features: [
      '14.yy',
      'Türk edebiyatında mahlas kullanan ilk şairdir',
      'Mutasavvıf bir şairdir',
      'Mantıku’t-Tayr önemli eseridir',
    ],
  },
  {
    name: 'AHMEDİ',
    period: 'DİVAN ŞİİRİ',
    features: [
      '14.yy',
      'Tasavvufa az yer vermiştir',
      'Türkçeyi iyi kullanan şairlerdendir',
      'Türk edebiyatının kurucu şairleri arasında yer alır',
      'İskendername önemli eseridir',
    ],
  },
  {
    name: 'KADI BURHANEDDİN',
    period: 'DİVAN ŞİİRİ',
    features: ['14.yy', 'Azeri Türkçesi', 'Tuyuğlarıyla tanınır'],
  },
  {
    name: 'SÜLEYMAN ÇELEBİ',
    period: 'DİVAN ŞİİRİ',
    features: ['15.yy', 'En tanınmış mevlid yazarıdır', 'Vesiletü’n-Necat'],
  },
  {
    name: 'ALİ ŞİR NEVAİ',
    period: 'DİVAN ŞİİRİ',
    features: [
      '15.yy',
      'Çağatay Türkçesi',
      'Türk dil birliğini kurmaya çalışmıştır',
      'İlk hamse sahibidir',
      'Edebiyatımızdaki ilk tezkirenin sahibidir - Mecalisü’n-Nefais',
    ],
  },
  {
    name: 'ŞEYHİ',
    period: 'DİVAN ŞİİRİ',
    features: [
      '15.yy',
      'Divan edebiyatının önde gelen hiciv şairlerindendir',
      'Harname önemli eseridir',
    ],
  },
  {
    name: 'AHMED-İ DAİ',
    period: 'DİVAN ŞİİRİ',
    features: [
      '15.yy',
      'İlk inşa örneğinin sahibidir - Teressül',
      'Camasbname önemli eseridir',
    ],
  },
  {
    name: 'AHMED PAŞA',
    period: 'DİVAN ŞİİRİ',
    features: [
      '15.yy',
      'Fatih Dönemi’nin şairlerindendir',
      'Kaside türünde eserleri başarılıdır',
      'Kerem Kasidesi ve Güneş Kasidesi',
    ],
  },
  {
    name: 'FUZULİ',
    period: 'DİVAN ŞİİRİ',
    features: [
      '16.yy',
      'Azeri Türkçesi',
      'Gazel şairi olarak tanınır',
      'Tasavvuf ve aşk önemli temalarıdır',
      'Istırap Şairi',
    ],
  },
  {
    name: 'TAŞLICALI YAHYA',
    period: 'DİVAN ŞİİRİ',
    features: [
      '16.yy',
      'Hamse sahibi',
      'Fuzuli’den sonra yüzyılın en üstün mesnevi sanatkârı',
      'İstanbul Şehrengizi',
      'Şehzade Mustafa Mersiyesi ünlüdür',
    ],
  },
  {
    name: 'BAKİ',
    period: 'DİVAN ŞİİRİ',
    features: [
      '16.yy',
      'Din, tasavvuf konularını işlemez',
      'En ünlü gazel şairlerindendir',
      'Sultanu’ş-Şuara',
      'Rindane gazel',
      'Kanuni Mersiyesi',
      'Mahallileşme akımı',
    ],
  },
  {
    name: 'ZATİ',
    period: 'DİVAN ŞİİRİ',
    features: ['16.yy', 'Edirne Şehrengizi', 'Şem ü Pervane'],
  },
  {
    name: 'BAĞDATLI RUHİ',
    period: 'DİVAN ŞİİRİ',
    features: ['16.yy', 'Terkib-i Bent’i ünlüdür'],
  },
  {
    name: 'NEFİ',
    period: 'DİVAN ŞİİRİ',
    features: [
      '17.yy',
      'IV. Murat Dönemi’nde zirveye ulaşmıştır',
      'Kaside ve hicivleriyle tanınmıştır',
      'Ölçüsüz bir şairdir',
      'Övgü ve yergi şairi',
      'Siham-ı Kaza eseri ünlüdür',
    ],
  },
  {
    name: 'NABİ',
    period: 'DİVAN ŞİİRİ',
    features: [
      '17.yy',
      'Hakimane şiir / hikemi şiir',
      'Didaktik tarz',
      'Hikmetli sözler',
      'Öğüt ve nasihat içeren tarzda eserler',
      'Hayriyye ve Hayrabad eserleri ünlüdür',
    ],
  },
  {
    name: 'NAİLİ',
    period: 'DİVAN ŞİİRİ',
    features: [
      '17.yy',
      'Sebk-i Hindi akımı',
      'Tasavvufi içerik',
      'İlk şarkı şairi',
    ],
  },
  {
    name: 'NEDİM',
    period: 'DİVAN ŞİİRİ',
    features: [
      '18.yy',
      'Mahallileşme akımı',
      'İstanbul şairi',
      'Lale Devri şairi',
      'Aşk, sevgili, eğlence meclisleri',
      'Şuhane gazel',
      'Tasavvuf etkisi yoktur',
      'Şarkı türünün önemli ismidir',
    ],
  },
  {
    name: 'ŞEYH GALİP',
    period: 'DİVAN ŞİİRİ',
    features: [
      '18.yy',
      'Mutasavvıf şair',
      'Galata Mevlevihanesi’nin şeyhidir',
      'Sebk-i Hindi akımı',
      'Divan şiirinin son büyük temsilcisidir',
      'Sembolik, soyut ve kapalı bir anlatımı vardır',
      'Hüsn ü Aşk eseri önemlidir',
    ],
  },
]
