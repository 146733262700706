import React, { useEffect } from 'react'
import './box.css'

const Box = ({ item, currentClass, setCurrentClass }) => {
  //   const [selectedValue, setSelectedValue] = useState('front')

  function changeSide(e) {
    const showClass = 'show-' + e.target.value
    setCurrentClass(showClass)
    // setSelectedValue(e.target.value)
  }

  useEffect(() => {
    const radioGroup = document.querySelector('.radio-group')
    if (radioGroup) {
      radioGroup.addEventListener('change', changeSide)
    }

    return () => {
      if (radioGroup) {
        radioGroup.removeEventListener('change', changeSide)
      }
    }
  }, [])

  const test = (process) => {
    let e = { target: { value: process } }
    changeSide(e)
  }

  return (
    <>
      <div className='scene'>
        <div className={`box ${currentClass}`}>
          <div
            className='box__face box__face--front'
            onClick={() => test('back')}
          >
            {item?.name}
          </div>
          <div
            className='box__face box__face--back'
            onClick={() => test('front')}
          >
            {item?.features?.map((item) => (
              <>
                <div className='d-flex justify-content-cente align-items-cente my-2'>
                  <div
                    className='mx-2 mt-1'
                    style={{
                      backgroundColor: '#00FF7F',
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                    }}
                  ></div>
                  <span>{item}</span>
                </div>
              </>
            ))}
          </div>
          <div className='box__face box__face--right'>
            <span className='d-block'>E</span>
            <span className='d-block'>D</span>
            <span className='d-block'>E</span>
            <span className='d-block'>B</span>
            <span className='d-block'>İ</span>
            <span className='d-block'>K</span>
            <span className='d-block'>O</span>
            <span className='d-block'>D</span>
          </div>
          <div className='box__face box__face--left'>left</div>
          <div className='box__face box__face--top'>top</div>
          <div className='box__face box__face--bottom'>bottom</div>
        </div>
      </div>
      {/* <div className='radio-group'>
        <label>
          <input
            type='radio'
            name='rotate-cube-side'
            value='front'
            checked={selectedValue === 'front'}
            onChange={changeSide}
          />{' '}
          front
        </label>
        <label>
          <input
            type='radio'
            name='rotate-cube-side'
            value='right'
            checked={selectedValue === 'right'}
            onChange={changeSide}
          />{' '}
          right
        </label>
        <label>
          <input
            type='radio'
            name='rotate-cube-side'
            value='back'
            checked={selectedValue === 'back'}
            onChange={changeSide}
          />{' '}
          back
        </label>
        <label>
          <input
            type='radio'
            name='rotate-cube-side'
            value='left'
            checked={selectedValue === 'left'}
            onChange={changeSide}
          />{' '}
          left
        </label>
        <label>
          <input
            type='radio'
            name='rotate-cube-side'
            value='top'
            checked={selectedValue === 'top'}
            onChange={changeSide}
          />{' '}
          top
        </label>
        <label>
          <input
            type='radio'
            name='rotate-cube-side'
            value='bottom'
            checked={selectedValue === 'bottom'}
            onChange={changeSide}
          />{' '}
          bottom
        </label>
      </div> */}
    </>
  )
}

export default Box
