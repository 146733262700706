import learn from './../../images/learn.png'
import podium from './../../images/podium.png'
import rotate from './../../images/rotate.png'
import LottieFiles from '../../components/lottie'
import examAnimation from '../../assets/animations/exam.json'
import bookAnimation from '../../assets/animations/book.json'
import quizAnimation from '../../assets/animations/quiz.json'

const Menu = ({ option, setOption }) => {
  const loaderAnimationStyle = {
    width: '110px',
    height: '150px',
  }
  return (
    <div className='row'>
      <div className='mb-2 text-center'>
        <h2 style={{ fontWeight: 500 }}>Bilgiye ulaşmanın daha basit yolu!</h2>
      </div>
      <div
        className='col-10 offset-1 col-md-6 offset-md-3 col-xl-6 offset-xl-3 d-xl-flex justify-content-around align-items-center py-5 px-3'
        style={{
          backgroundColor: 'white',
          borderRadius: '4px',
          boxShadow: '0 0.25rem 1rem gray',
        }}
      >
        <div
          onClick={() =>
            setOption({
              ...option,
              tab: 2,
            })
          }
          className='text-center p-4 mx-1 menuBox d-flex flex-column align-items-center'
          style={{ cursor: 'pointer' }}
        >
          <LottieFiles json={bookAnimation} style={loaderAnimationStyle} />

          <div className='mt-4'>
            <div className='d-flex justify-content-center align-items-center mb-1'>
              <img
                src={learn}
                width='25px'
                height='25px'
                className='mx-2'
                alt=''
              />
              <span style={{ fontWeight: 600, fontSize: '20px' }}>
                Edebikodla Öğren
              </span>
            </div>
            <span className='d-block'>
              Hemen tıkla ve öğrenmeye <br /> başla
            </span>
          </div>
        </div>
        <div
          className='text-center p-4 mx-1 menuBox d-flex flex-column align-items-center'
          style={{ cursor: 'pointer' }}
        >
          <LottieFiles json={quizAnimation} style={loaderAnimationStyle} />
          <div className='mt-4'>
            <div className='d-flex justify-content-center align-items-center mb-1'>
              <img
                src={rotate}
                width='25px'
                height='25px'
                className='mx-2'
                alt=''
              />
              <span style={{ fontWeight: 600, fontSize: '20px' }}>
                Kendini Dene
              </span>
            </div>
            <span className='d-block'>
              Öğrendiklerini hemen test <br /> et
            </span>
          </div>
        </div>
        <div
          className='text-center p-4 mx-1 menuBox d-flex flex-column align-items-center'
          style={{ cursor: 'pointer' }}
        >
          <LottieFiles json={examAnimation} style={loaderAnimationStyle} />
          <div className='mt-4'>
            <div className='d-flex justify-content-center align-items-center mb-1'>
              <img
                src={podium}
                width='25px'
                height='25px'
                className='mx-2'
                alt=''
              />
              <span style={{ fontWeight: 600, fontSize: '20px' }}>
                Sonucunu Gör
              </span>
            </div>
            <span className='d-block'>
              Testin sonucunda kaçıncı <br /> olduğunu gör
            </span>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center align-items-center py-3 my-5'>
        <button
          onClick={() =>
            setOption({
              ...option,
              tab: 2,
            })
          }
          style={{ width: '100px', height: '100px' }}
          className='mainButton'
        >
          Hemen Başla
        </button>
      </div>
    </div>
  )
}
export default Menu
